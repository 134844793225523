import QueryString from "querystring";
import type { GetServerSidePropsContext, GetServerSideProps } from "next";
import { PageRenderer } from "@/renderers";
import type { ServiceArgs } from "@/services";
import { TemplatePageService } from "@/services";
import type { PageResponse } from "@/types";
import { AuthenticatedUser, Authentication } from "@/libs";
import { PATHS } from "@/services/libs";

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { resolvedUrl, req, res, query } = context;
  const accessToken = await Authentication.getUserAccessToken(req, res);
  const user: AuthenticatedUser | undefined = await Authentication.getUser(
    req,
    res
  );

  if (user) {
    return {
      redirect: {
        destination: PATHS.home,
        permanent: false,
      },
    };
  }

  const serviceArgs: ServiceArgs = {
    path: resolvedUrl,
    accessToken,
    user,
    queryParams: QueryString.stringify(query, "&", "=", {
      encodeURIComponent: (str) => str,
    }),
  };
  const page = await TemplatePageService.retrievePageData(serviceArgs);

  return {
    props: { page },
  };
};

const TemplatePage = ({ page }: PageResponse) => {
  if (page) return PageRenderer.render(page);
  return null;
};

export default TemplatePage;
